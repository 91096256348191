<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import repas from "./repas";
import {getDropDownElementsByTypeApi} from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

export default {
    page: {
        title: "Reception ODS",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*SideButtons*/repas},
    props:{
      ODS:{
        type:Array,
        default:undefined
      }
    },
    data() {
      return {
      tableData: [],
      title: "Ordre de Service",
      selectedBdv:0,
      restaurants:[],
      nows:now,
      ODS_info: {
        Bdv:'',
        ID_CTT:'',
        prestataire:'',
        contract:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
        Repas:{},
      send:false,
      RepasService:[],
      typeRepas:[],
      have:false,
      keys:'',
      bases:[],
      prests:[],
      contracts:[],
      periods:[],
      filtredRepas:[]

    };
  },
  watch:{
    ODS:{
      handler(val){
        if(val.length > 0){
          this.ODS_info.id                   = val[0].id;
          this.ODS_info.ref                  = val[0].ref;
          this.ODS_info.type_ODS             = val[0].type_ODS;
          this.ODS_info.Bdv                  = val[0].Bdv;
          this.ODS_info.ID_CTT               = val[0].contract.id;
          this.ODS_info.contract             = val[0].contract;
          this.ODS_info.prestataire          = val[0].service_provider;
          this.ODS_info.date_ODS             = val[0].date_ODS;
          this.ODS_info.date_ODS_format      = val[0].date_ODS_format;
          this.ODS_info.date_exec_ODS        = val[0].date_exec_ODS;
          this.ODS_info.date_exec_ODS_tom    = val[0].date_exec_ODS_tom;
          this.ODS_info.date_exec_ODS_format = val[0].date_exec_ODS_format;
          this.ODS_info.period               = val[0].period;
          this.ODS_info.lifebase             = val[0].lifebase;
          this.ODS_info.done                 = val[0].done;
          this.ODS_info.comments             = val[0].comment;
          this.ODS_info.items                = val[0].items;
          this.ODS_info.read_at              = val[0].read_at;
          this.ODS_info.hour                 = val[0].hour;
          this.RepasService                  = Object.keys(val[0].items);
          this.filtredRepas                  = val[0].items;
          this.RepasService.forEach(element => (this.Repas[element]=undefined))
          
          this.selectedContract       = val[0].contract;
          this.selectedBdv            = val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest          = val[0].service_provider;
          this.have                   = true;
        }
        else this.resetData()
      }
    }
  },
  mounted(){
    this.getLifeBases();
    // this.getServiceRepas();
    this.getlistPeriod();
  },

  methods:{
    getLifeBases(){
      this.$http.post('/base/lifebases/list')
      .then(response => {
          this.bases = response.data.original.list;
      })
      .catch(error => console.log(error))
    },
    onComplete(){
      let msg="de Valider la réalisation cet ODS ?";
      // parseInt(this.ODS_info.hour) >= 19 ? msg="de Valider la réalisation cet ODS ?"  : msg ="d'enregistré ses informations ?";
      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log(this.Repas);
              this.$http.post('/ods/OdsRestauration/reception',{
                  ods:this.ODS_info, items:this.Repas
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',response.data);
                  if(parseInt(this.ODS_info.hour)>=19)Swal.fire("Félicitations!", "Commande Receptionné avec succées", "success");
                  else Swal.fire("Félicitations!", "Informations Enregistré avec succées", "success");
                  this.resetData();
                  this.send=false;
              })
              .catch(error => console.log(error))
            }
          })
      
    },
    getServiceRepas(){
      this.$http.post('/ods/OdsRestauration/listRepas')
      .then(response => {
        let List = response.data;
        
        List.forEach(element => {
            // element.type_period = element.type_period.split("");
            element.value = false;
          });
          // console.log(List);
        this.RepasService = List;
        
      })
      .catch(error => console.log(error))
    },
    getTypeRepas(){
      this.$http.post('base/operations3D/restauration/typeRepas/list')
      .then(response => {
        let List = response.data;
        
        List.forEach(element => {
            element.value = false;
          });
        this.typeRepas = List;
      })
      .catch(error => console.log(error))
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    onValidate(data){
      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de vouloir accusé la réception de cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.$http.post('/ods/OdsRestauration/validate',{
              ods:this.ODS_info,status:data
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',response.data);
                  if(data == "Recieved") Swal.fire("Félicitations!","L'accusé de réception de l' " +this.ODS_info.ref+" a été bien envoyé", "success");
                  
                  this.resetData();
                  this.send=false;
                  this.validate=false;
                  this.confirm=false;
              })
              .catch(error => console.log(error))
            }
          })
          
    },
    getlistPeriod(){
      getDropDownElementsByTypeApi("meal_period")
      .then(res => {
        // console.log(res);
        this.periods = res.data.original.list
      })
      .catch(error => (console.log(error)))
    },
    resetData(){
      this.ODS_info = {
        Bdv:'',
        ID_CTT:'',
        prestataire:'',
        contract:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      this.RepasService = [],
        this.filtredRepas = [],
        this.have=false
      this.send = true;
    }
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <!-- <SideButtons :save="send" :contract="ODS_info.contract" @add="add = $event" @save="save = $event" @edit=" edit = $event" @confirm=" confirm = $event" @validate=" validate = $event" @delete=" destroy = $event" /> -->
        
              <div class="p-3">
                <div class="">
                  <div class="row" v-if="ODS_info.id&&ODS_info.date_exec_ODS != nows ">
                    <div class="col">
                      <b-alert variant="warning" show >
                          <i class="mdi mdi-alert-circle"></i> L'enregistrement et la confirmation sera active uniquement à partie du jour de l'execution Le {{ODS_info.date_exec_ODS_format}} pour 48h.
                      </b-alert>
                    </div>
                  </div>
                      <div class="row">

                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Contrat</label>
                                <input class="form-control" readonly v-model="ODS_info.contract.ref" />
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <input v-if="ODS_info.lifebase" class="form-control" readonly v-model="ODS_info.lifebase.name"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.Bdv"/>
                              </div>
                            </div>

                            <div class="col-lg-4">
                                <label for="">Prestataire *</label>
                                <input v-if="ODS_info.prestataire" class="form-control" readonly v-model="ODS_info.prestataire.prestname"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.prestataire"/>
                                <!-- <select class="form-control" disabled v-model="ODS_info.prestataire">
                                    <option value="0">Selectionner le Prestataire</option>
                                    <option v-for="prest in prests" :key="prest.value" :value="prest.value">{{prest.text}}</option>
                                </select> -->
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="">Date ODS</label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Date execution</label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_exec_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Type de Periode *</label>
                                <select class="form-control" disabled v-model="ODS_info.period">
                                    <option value="0">Selectionner la periode</option>
                                    <option v-for="period in periods" :key="period.id" :value="period.id">{{period.designation}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Commentaires</label>
                                <textarea
                                  v-model="ODS_info.comments"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  readonly
                                ></textarea>
                              </div>
                            </div>
                        </div>

                      <!-- end row -->
                </div>
                <!-- end card-body -->
          </div>
          <div class="p-3">
            <h4>Type de Repas</h4>
            <div class="row mt-2" v-for="(rep,i) in RepasService" :key="i">
              <div class="col" >
                <repas :key="rep" :ODS="ODS_info" :pos="rep" :service="filtredRepas" :send="send" @getvalues="Repas[`${rep}`] = $event" />
              </div>
            </div>
          </div>
          
          
          <div class="row">
            
            <div class="col text-right mr-2 mb-3" v-if="ODS_info.id">
              <b-button :disabled="ODS_info.done == 1 || ODS_info.read_at != null" @click="onValidate('Recieved')" variant="info" class="btn-label mr-2">
                  <i class="fas fa-paper-plane label-icon"></i> Accusé de Réception
              </b-button>

              <!-- <b-button v-if="parseInt(ODS_info.hour) >= 19" :disabled="ODS_info.done == 1 || ODS_info.date_exec_ODS != nows || ODS_info.date_exec_ODS_tom != nows || (ODS_info.date_exec_ODS == nows && ODS_info.read_at == null)" @click="onComplete" variant="primary" class="btn-label">
                  <i class="bx bx-check-double label-icon"></i> Valider
              </b-button> -->
              <b-button v-if="ODS_info.date_exec_ODS_tom == nows" :disabled="ODS_info.date_exec_ODS_tom != nows" @click="onComplete" variant="primary" class="btn-label">
                  <i class="bx bx-check-double label-icon"></i> Enregistrer & Valider
              </b-button>
              <b-button v-else :disabled="ODS_info.done == 1 || ODS_info.date_exec_ODS != nows || (ODS_info.date_exec_ODS == nows && ODS_info.read_at == null)" @click="onComplete" variant="primary" class="btn-label">
                  <i class="bx bx-check-double label-icon"></i> Enregistrer & Valider
              </b-button>
              
              
            </div>
          </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
