<script>
import appConfig from "@/app.config";
export default {
    // props:{
    //     id:{},
    //     types:{},
    //     send:{},
    //     meal:{
    //         type:Array,
    //     },
    //     pos:{}
    // },
    props:['service','ODS','send','pos'],
    data() {
        return {
            active:false,
            keys:[],
            data:{},
            achievement:{}
        }
    },
    watch:{
        pos(){
            console.log(this.pos);
        },
        send:{
            handler(val){
                if(val) this.resetData();
            }
        },
        active:{
            handler(val){
                // console.log(this.service[this.pos][0].meal_type.id);
                if (val) for (let index = 0; index < this.service[this.pos].length; index++) {
                        this.data[this.service[this.pos][index].meal_type.designation] = this.service[this.pos][index]
                        if(this.service[this.pos][index].meal_achieve_amount == "") this.data[this.service[this.pos][index].meal_type.designation].meal_achieve_amount = 0;

                        if(this.service[this.pos][index].total_amount) this.data[this.service[this.pos][index].meal_type.designation].unit_price = this.service[this.pos][index].prestation.unit_price 
                        else this.data[this.service[this.pos][index].meal_type.designation].unit_price =this.service[this.pos][index].unit_price; 
                        
                    }
                this.keys = Object.keys(this.data);
            }
        }
        
    },
    methods:{
        emitData(){
            this.$emit('getvalues',this.data);
        },
        resetData(){
            this.data = {}
        },
        getTotal(){
            let total = 0;
            if(this.service[this.pos][0].meal_amount){
                total = 0;
                this.service[this.pos].forEach(element => {
                    total+=parseInt(element.meal_amount);
                });
                if (total > 0) this.emitData();
                return total;
            }
            
            this.keys.forEach((element,i) => {
                total += parseInt(this.data[element].meal_amount)
            })
            // total = parseInt(this.data.Ordinary_today['meal_amount']) + parseInt(this.data.Improved_today['meal_amount']) + parseInt(this.data.VIP_today['meal_amount']);
            // if (total>0) this.emitData();
            return total;
        },
        getTotalR(){
             if(this.service[this.pos][0].meal_achieve_amount && this.service[this.pos][0].meal_achieve_amount != ""){
                let totalR = 0;
                this.service[this.pos].forEach(element => {
                    totalR+=parseInt(element.meal_achieve_amount);
                });
                if (totalR > 0) this.emitData();
                return totalR;
            }
            else {
                let totalR = 0; 
                this.keys.forEach((element,i) => {
                    totalR += parseInt(this.data[element].meal_achieve_amount)
                })
                // totalR= parseInt(this.data.Ordinary_today.meal_achieve_amount) + parseInt(this.data.Improved_today.meal_achieve_amount) + parseInt(this.data.VIP_today.meal_achieve_amount);
                if (totalR>0) this.emitData(); else totalR=0;
                return totalR;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                this.getTotal();
            }
        }
    }
}
</script>
<template>
    <div>
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> {{service[this.pos][0].meal_service.designation}}</div>
                  <div class="col text-right">
                      <span>Total Repas Demander: {{getTotal()}} </span> / <span>Total Réaliser: {{getTotalR()}}</span>
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${pos}`" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg" >
                    <div v-for="(repas,i) in keys" :key="i">
                        <h4>{{repas}}</h4>
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="">Consommation Demander</label>
                                <input type="text" class="form-control bg-light" readonly v-model="data[repas].meal_amount" >
                            </div>
                            <div class="col-lg-4">
                                <label for="">Consommation Réaliser</label>
                                <input type="number" min="0" class="form-control" :disabled="ODS.read_at == null" v-model="data[repas].meal_achieve_amount" @keypress="isNumber($event)"  placeholder="50">
                            </div>
                        </div>
                    </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
    </div>
</template>